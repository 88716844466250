.modal {
    width: 100%;
    max-width: 496px;
    padding: 0;
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    padding: 0 var(--spacing-core-6) var(--spacing-core-6) var(--spacing-core-6);
}
